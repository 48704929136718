import UAParserContext from '@/context/ua-parser'
import { giphyIndigo } from '@giphy/colors'
import { IChannel } from '@giphy/js-types'
import { Gif, Loader } from '@giphy/react-components'
import Link from 'next/link'
import { useContext, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import useWindowSize from 'react-use/lib/useWindowSize'
import styled from 'styled-components'
import { desktopContent } from 'ui/src/constants'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import useClientRender from '../../util/use-client-render'

const Label = styled.div`
    background-color: ${giphyIndigo};
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 2px 10px;
`

type P = Parameters<typeof useFetchData<IChannel>>[0]

type Props = { channel: IChannel; fetchData: P['fetchData'] }

/**
 * A fixed size grid with titles
 */
const ChannelGrid = ({ channel, fetchData }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const canRender = useClientRender()

    const loaderConfig = useMemo(() => (canRender ? { rootMargin: '0px 0px 250px 0px' } : {}), [canRender])
    const { ref, inView } = useInView(loaderConfig)
    const { doneFetching, gifs: children } = useFetchData<IChannel>({
        initialData: channel.children,
        fetchData,
        triggerFetch: inView,
        checkDone: () => channel.children.length === channel.num_children,
    })
    // no way to do grids on mobile yet since we don't know window width on server
    let { width: windowWidth } = useWindowSize()
    // go by server ua parsing
    let isDesktop = deviceType === 'desktop'
    // switch to window width on client
    const columns = isDesktop ? 3 : 2
    const gridWidth = isDesktop ? desktopContent : windowWidth
    const gridSpacing = 6
    const totalGridSpacing = gridSpacing * (columns - 1)
    const columnWidth = (gridWidth - totalGridSpacing) / columns
    const desktopHeight = 138
    const itemHeight = isDesktop ? desktopHeight : (columnWidth * 9) / 16
    const gridTemplateColumns = `repeat(${columns}, ${columnWidth}px)`

    const labelHeight = 26
    return isDesktop || canRender ? (
        <div>
            <div
                className="grid"
                style={{
                    width: gridWidth,
                    gridTemplateColumns,
                    gap: gridSpacing,
                    gridAutoRows: `${itemHeight + labelHeight}px`,
                }}
            >
                {children
                    ? children.map((kid: IChannel) => {
                          return kid.featured_gif ? (
                              <Link href={kid.url} key={kid.id}>
                                  <div key={kid.id} style={{ width: columnWidth }}>
                                      <Gif
                                          borderRadius={0}
                                          className={
                                              kid.featured_gif.is_sticker
                                                  ? '[&_img]:object-contain'
                                                  : '[&_img]:object-cover'
                                          }
                                          hideAttribution
                                          gif={kid.featured_gif}
                                          width={columnWidth}
                                          height={itemHeight}
                                          noLink
                                      />
                                      <Label>{kid.short_display_name || kid.display_name}</Label>
                                  </div>
                              </Link>
                          ) : (
                              <div key={kid.id} style={{ width: columnWidth }}>
                                  <img
                                      alt={kid.description}
                                      style={{ width: columnWidth, height: itemHeight, objectFit: 'cover' }}
                                      src={channel.user.avatar_url}
                                  />
                                  <Label>{kid.short_display_name || kid.display_name}</Label>
                              </div>
                          )
                      })
                    : null}
            </div>
            {!doneFetching && (
                <div ref={ref}>
                    <Loader />
                </div>
            )}
        </div>
    ) : null
}

export default ChannelGrid
