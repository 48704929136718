'use client'
import { getChannelChildren, getChannelGifsById } from '@/app/util/channel-api'
import ChannelTags from '@/components/carousel/channel-tags'
import ChannelGrid from '@/components/grid/channel-grid'
import Grid from '@/components/grid/grid'
import { SectionGroup } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { cn } from '@/util/cn'
import { IChannel, IGif } from '@giphy/js-types'
import { useContext } from 'react'
import { desktopContent } from 'ui/src/constants'
import Banner from '../components/banner'
import ChannelInfo from '../components/channel-info'
import ChannelPageHeader from '../components/page-header'
import { desktopContainerCss } from '../constants'

const clientsideFetchLimit = 15

type Props = { channel: IChannel; initialGifs?: IGif[] }
const containerCss = 'flex flex-col gap-5 [&_section]:flex [&_section]:flex-col [&_section]:gap-2'
const SubChannelPageContents = ({ channel, initialGifs }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const getTrackingUrl = (page: number) => `/${channel.slug}/page/${page}`
    return (
        <>
            <Banner channel={channel} />
            <div className={cn(containerCss, desktopContainerCss)}>
                <ChannelInfo channel={channel} display_name={channel.display_name} />
                <div className={cn(containerCss)}>
                    <div className="flex flex-col gap-3 px-1.5 py-px">
                        <ChannelPageHeader channel={channel} title={channel.display_name} />
                        <ChannelTags channel={channel} />
                    </div>
                    <SectionGroup margin="10px 0" gap={20}>
                        {channel.children?.length > 0 && (
                            <section>
                                <ChannelGrid
                                    key={`ChannelGrid-${channel.id}`}
                                    channel={channel}
                                    fetchData={(offset: number) =>
                                        getChannelChildren(channel.id, document.cookie, {
                                            offset,
                                            limit: clientsideFetchLimit,
                                        })
                                    }
                                />
                            </section>
                        )}
                        <section>
                            <Grid
                                columns={deviceType === 'desktop' ? 3 : undefined}
                                width={deviceType === 'desktop' ? desktopContent : undefined}
                                initialGifs={initialGifs}
                                fetchGifs={async (offset: number) => {
                                    const result = await getChannelGifsById(
                                        channel.id,
                                        { offset, limit: clientsideFetchLimit },
                                        document.cookie as string
                                    )
                                    return result
                                }}
                                gaTrackingUrl={getTrackingUrl}
                                key={`Grid-${channel.id}`}
                            />
                        </section>
                    </SectionGroup>
                </div>
            </div>
        </>
    )
}

export default SubChannelPageContents
